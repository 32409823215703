import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import algoliasearch from 'algoliasearch/lite';
import { connectSearchBox, InstantSearch } from 'react-instantsearch-dom';

import withLocation from '../components/common/withLocation';

import BaseLayout from '../components/layout/base-layout';
import PageColumn from '../components/layout/page-column';
import SearchResult from '../components/search/search-result';
import Section from '../components/layout/section';
import SectionHeader from '../components/common/section-header';

const searchIndices = [{ name: 'cookbooking-books', title: 'cookbooking-books'}];

const SectionTitleWithQueryComponent = ({ search }) => {
  const { q } = search;
  
  if (q) {
    return (
      <SectionHeader label={ `Search Results for ‘${q}’` } />
    );
  }
  
  return null;
};

const SearchPageResultsWithQueryComponent = ({ search }) => {
  const { q } = search;
  const searchClient = algoliasearch(
    process.env.GATSBY_COOKBOOKS_ALGOLIA_APP_ID,
    process.env.GATSBY_COOKBOOKS_ALGOLIA_SEARCH_KEY,
  );
  
  const searchInputRef = useRef();
  
  if (q) {
    return (
      <>
      <InstantSearch
        searchClient={ searchClient }
        indexName={ 'cookbooking-books' }
      >
        <VirtualSearchBox defaultRefinement={ q } />
        <SearchResult
          show={ true }
          indices={ [{ name: 'cookbooking-books', title: 'cookbooking-books' }] }
        />
      </InstantSearch>
      <div className="search-page-results__search-form">
        <form action="/search" method="get">
          <input ref={searchInputRef} type="text" name="q" />
          <input type="submit" value="Search" />
        </form>
      </div>
      </>
    );
  }
  
  return (
    <div className="search-page-results__search-form">
      <form action="/search" method="get">
        <input ref={searchInputRef} type="text" name="q" />
        <input type="submit" value="Search" />
      </form>
    </div>
  );
};

const SearchPageResultsWithQuery = withLocation(SearchPageResultsWithQueryComponent);
const SectionTitleWithQuery = withLocation(SectionTitleWithQueryComponent);

const VirtualSearchBox = connectSearchBox(() => null);

const SearchPage = (props) => {
  const { location, title, children } = props;

  return (
    <BaseLayout>
      <Helmet>
        <title>Search</title>
        <meta
          property="og:title"
          content="Search"
        />
      </Helmet>
        <Section topSpacing="section" bottomSpacing="section">
          <PageColumn>
            <SectionTitleWithQuery />
            <SearchPageResultsWithQuery />
          </PageColumn>
        </Section>
    </BaseLayout>
  )
};

export default SearchPage;