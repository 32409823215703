import React from 'react';

import CategoryBarItem from './bar-item';

import '../../styles/categories/bar.scss';

export default (props) => {
  const categoryNodes = props.categoryNodes;

  return (
    <ul className="category-bar">
      {categoryNodes.map(({ data }) => (
        <>
          <CategoryBarItem
            key={ data.Slug }
            data={ data }
          />{' '}
        </>
      ))}
    </ul>
  );
};