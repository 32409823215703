import React from 'react';
import { graphql } from 'gatsby';

export const formattedBookTitle = (title, subtitle) => {
  if (subtitle && subtitle.length > 0) {
    return (
      <>
        {`${title}: `} 
        <span className="subtitle">{subtitle}</span>
      </>
    );
  }

  return title;
}

export const amazonUrlFromAsin = (asin) => {
  return `https://www.amazon.com/dp/${asin}/ref=nosim?tag=readtoeat-20`;
}

export const bookshopUrlFromIsbn = (isbn) => {
  return `https://bookshop.org/a/1976/${isbn}`;
}

export const bookMetadataFragment = graphql`
fragment BookMetadata on AirtableData {
  Published
  Slug
  Sort_Title
  Subtitle
  Title
}

fragment BookForListing on AirtableData {
  ISBN_13
  Kindle_ASIN
  Kindle_Price
  Ordered_Authors
  Published
  Slug
  Sort_Title
  Subtitle
  Title
  Authors {
    data {
      Name
      Slug
    }
  }
  Categories {
    data {
      Label
      Slug
    }
  }
  Signed_Cover_URL
}
`;