import React from 'react';
import { Link } from 'gatsby';

export default (props) => {
  const data = props.data;

  return (
    <li>
      <Link to={`/category/${data.Slug}`}>
        <span className="label">{data.Label}</span>
        <div className="underlay"></div>
      </Link>
    </li>
  );
};