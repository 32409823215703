import React from 'react';

import { Link } from 'gatsby';

const sortAuthors = (authorNodes, orderedAuthors) => {
  let sortedAuthors;

  if (authorNodes.length > 1 && orderedAuthors) {
    sortedAuthors = orderedAuthors.split('\n');
    sortedAuthors = sortedAuthors.map(author => {
      let filteredAuthors = authorNodes.filter(({ data }) => data.Slug === author);

      if (filteredAuthors.length === 0) {
        return null;
      }

      return filteredAuthors[0];
    })

    return sortedAuthors;
  }

  return authorNodes;
}

export default (props) => {
  const authors = sortAuthors(props.authorNodes, props.orderedAuthors);

  if (!authors) {
    return null;
  }

  if (authors.length === 1) {
    return (
      <>
        By{' '}
        {(props.suppressLinks) ? (
          authors[0].data.Name
        ) : (
          <Link to={`/author/${authors[0].data.Slug}`}>{authors[0].data.Name}</Link>
        )}
      </>
    );
  } else if (authors.length === 2) {
    return (
      <>
        By{' '}
        {(props.suppressLinks) ? (
          `${authors[0].data.Name} and ${authors[1].data.Name}`
        ) : (
          <>
            <Link to={`/author/${authors[0].data.Slug}`}>{authors[0].data.Name}</Link>{' '}
            and{' '}
            <Link to={`/author/${authors[1].data.Slug}`}>{authors[1].data.Name}</Link>
          </>
        )}
      </>
    );
  } else if (authors.length > 2) {
    return (
      <>
        By{' '}
        {authors.map(({ data }, idx) => (
          <>
            {(idx === authors.length - 1) ? (
              <>{' and '}</>
            ) : null}
            {(props.suppressLinks) ? (
              data.Name
            ) : (
              <Link to={`/author/${data.Slug}`}>{data.Name}</Link>
            )}
            {(idx < authors.length - 1) ? (
              <>{', '}</>
            ) : null}
          </>
        ))}
      </>
    );
  }
  
  return null;
};