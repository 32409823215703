import { Link } from 'gatsby';
import { default as React } from 'react';
import {
  connectHits,
  connectStateResults,
  Index,
} from 'react-instantsearch-dom';

import BookList from '../../components/books/list';

import '../../styles/search/search-page-results.scss';

const wrappedBooks = (hits) => {
  if (hits.length > 0) {
    return hits.map(hit => {
      return {
        data: {
          Title: hit.title,
          Subtitle: hit.subtitle,
          Slug: hit.slug,
          Signed_Cover_URL: hit.coverImage,
        }
      }
    });
  }
  
  return [];
};

const BookHit = ({ hit }) => {
  const titleJsx = (hit.subtitle && hit.subtitle.length > 0)
    ? (
      <>
        {hit.title}:<br /><span className="subtitle">{hit.subtitle}</span>
      </>
    ) : hit.title;
  
  return (
    <li>
      <Link to={ `/book/${hit.slug}` }>
        <span className="search-page-results__cover">
          <img src={hit.coverImage} alt={ `${hit.title} cover` } />
        </span>
        <span className="search-page-results__details">
          <span className="title">
            {titleJsx}
          </span>
          {hit.authors && hit.authors.length > 0 && (
            <span className="byline">
              By {hit.authors}
            </span>
          )}
        </span>
      </Link>
    </li>
  );
};

const ResultsListing = connectHits(({ hits }) => {
  if (hits.length === 0) {
    return (
      <div>No results</div>
    );
  }
  
  return (
    <ul>
      {hits.map(hit => <BookHit hit={ hit } />)}
    </ul>
  )
});

const HitsInIndex = ({ index, hits }) => (
  <Index indexName={index.name}>
    <ResultsListing />
  </Index>
);

const SearchResult = connectStateResults(({ searchResults, indices }) => (
  <div className="search-page-results">
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
));

export default SearchResult;
